import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function QuestionList({ questions, questionSelected }) {
  const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);

  useEffect(() => {
    let preSelectedQuestion = JSON.parse(
      localStorage.getItem("selectedQuestion")
    );
    if (preSelectedQuestion) {
      let selectedQuestionExistsInTheList = questions.find(
        (question) => question.id == preSelectedQuestion.id
      );
      if (selectedQuestionExistsInTheList) {
        setSelectedQuestion(selectedQuestionExistsInTheList);
      } else {
        setSelectedQuestion(questions[0]);
      }
    }
  }, [questions]);

  useEffect(() => {
    selectedQuestion && questionSelected(selectedQuestion);
    selectedQuestion &&
      localStorage.setItem(
        "selectedQuestion",
        JSON.stringify(selectedQuestion)
      );
  }, [selectedQuestion]);

  const handleQuestionClick = (event, question) => {
    setSelectedQuestion(question);
    questionSelected(question);
  };

  return (
    <>
      {questions && (
        <Box sx={{ width: "100%" }}>
          <Typography variant={"h5"}>Question List</Typography>
          <List
            component="nav"
            aria-label="questions"
            sx={{ maxHeight: "500px", overflow: "auto" }}
          >
            {questions.map((question, index) => (
              <ListItemButton
                sx={{
                  border: question.isVerified ? "none" : "1px solid orange",
                }}
                key={index}
                selected={selectedQuestion?.id === question.id}
                onClick={(event) => handleQuestionClick(event, question)}
              >
                <ListItemText primary={`${index + 1}. ${question.title}`} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      )}
    </>
  );
}

QuestionList.propTypes = {
  questions: PropTypes.array,
  questionSelected: PropTypes.func,
};
export default QuestionList;
